import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../utils";
import { RootState } from "../redux/store";
import { CreateMerchantName } from "./authorizationApi";
import { MerchantGroups } from "./supportingDocumentsApi";

export interface AuthorizationState {
  createMerchantNameStatus: QueryStatus;
  merchantGroupCreated: MerchantGroups | null;
}

const initialState: AuthorizationState = {
  createMerchantNameStatus: "idle",
  merchantGroupCreated: null,
};

export const createMerchantNameAsync = createAsyncThunk(
  "merchantName/create",
  async (payload: CreateMerchantName) => {
    const axios = authAxios();
    await axios.post(`/merchant_name`, payload);
    return payload.merchantGroup;
  },
);

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCreateMerchantNameStatus(state) {
      state.createMerchantNameStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createMerchantNameAsync.pending, (state) => {
        state.createMerchantNameStatus = "processing";
      })
      .addCase(createMerchantNameAsync.fulfilled, (state, action) => {
        state.createMerchantNameStatus = "success";
        state.merchantGroupCreated = action.payload;
      })
      .addCase(createMerchantNameAsync.rejected, (state) => {
        state.createMerchantNameStatus = "failed";
      });
  },
});

export const selectCreateMerchantNameStatus = (state: RootState) =>
  state.authorization.createMerchantNameStatus;
export const selectMerchantGroupCreated = (state: RootState) =>
  state.authorization.merchantGroupCreated;

export default authorizationSlice.reducer;
