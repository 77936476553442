import { dateFormatterDayMonthYearLong2Digits } from "@qivia/ui";

export const formatDate = (value: string | number | Date) =>
  value instanceof Date && !isNaN(value?.getTime())
    ? dateFormatterDayMonthYearLong2Digits(value)
    : "";

export const getDate = (value: string) => {
  if (!value) return null;
  const [day, month, year] = value.split("/");
  return new Date(`${month}/${day}/${year}`);
};
