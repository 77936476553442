import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import {
  CreateAsfInvoice,
  ExportAsfInvoice,
  AsfInvoicesList,
  AsfInvoicesListDisplayed,
} from "./asfInvoiceApi";
import axios from "axios";

export interface AsfInvoiceState {
  createAsfInvoiceStatus: QueryStatus;
  asfInvoicesList: AsfInvoicesListDisplayed[];
  asfInvoicesListStatus: QueryStatus;
  asfInvoiceExportStatus: QueryStatus;
  asfInvoiceDownloadStatus: QueryStatus;
  asfInvoiceExportLink: ExportAsfInvoice;
}

const initialState: AsfInvoiceState = {
  createAsfInvoiceStatus: "idle",
  asfInvoicesList: [],
  asfInvoicesListStatus: "idle",
  asfInvoiceExportStatus: "idle",
  asfInvoiceDownloadStatus: "idle",
  asfInvoiceExportLink: {
    presignedUrl: null,
    fileName: null,
  },
};

export const createAsfInvoiceAsync = createAsyncThunk(
  "createAsfInvoiceAsync/call",
  async (payload: CreateAsfInvoice) => {
    const axios = authAxios();
    await axios.post(`asf_invoice_creation`, payload);
  },
);

export const getAsfInvoicesListAsync = createAsyncThunk(
  "getAsfInvoicesListAsync/call",
  async (payload: { company: string }) => {
    const axios = authAxios();
    const response = await axios.get<AsfInvoicesList[]>(
      `/company/${payload.company}/asf_invoices`,
    );
    const formattedResult = response.data.sort(
      (a, b) =>
        new Date(b.beginningDate).getTime() -
        new Date(a.beginningDate).getTime(),
    );

    return formattedResult.map((r) => {
      return {
        ...r,
        transactionsAmountTtc: r.transactionsAmountTtc / 100,
      };
    });
  },
);

export const asfInvoiceExportAsync = createAsyncThunk(
  "asfInvoiceExport/call",
  async (payload: { bucketFileName: string; number: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/asf_invoice/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: `${payload.number}.pdf`,
    };
  },
);

export const asfInvoiceDownloadAsync = createAsyncThunk(
  "asfInvoiceDownload/call",
  async (asfInvoiceExportLink: ExportAsfInvoice) => {
    if (asfInvoiceExportLink.presignedUrl && asfInvoiceExportLink.fileName) {
      const response = await axios({
        url: asfInvoiceExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });

      const a = document.createElement("a");
      a.download = asfInvoiceExportLink.fileName || "yourAsfInvoice.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const asfInvoiceSlice = createSlice({
  name: "asfInvoice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createAsfInvoiceAsync.pending, (state) => {
        state.createAsfInvoiceStatus = "processing";
      })
      .addCase(createAsfInvoiceAsync.fulfilled, (state) => {
        state.createAsfInvoiceStatus = "success";
        state.asfInvoicesListStatus = "idle";
      })
      .addCase(createAsfInvoiceAsync.rejected, (state) => {
        state.createAsfInvoiceStatus = "failed";
      })
      .addCase(getAsfInvoicesListAsync.pending, (state) => {
        state.asfInvoicesListStatus = "processing";
      })
      .addCase(getAsfInvoicesListAsync.fulfilled, (state, action) => {
        state.asfInvoicesList = action.payload;
        state.asfInvoicesListStatus = "success";
      })
      .addCase(getAsfInvoicesListAsync.rejected, (state) => {
        state.asfInvoicesListStatus = "failed";
      })
      .addCase(asfInvoiceDownloadAsync.pending, (state) => {
        state.asfInvoiceDownloadStatus = "processing";
      })
      .addCase(asfInvoiceDownloadAsync.fulfilled, (state) => {
        state.asfInvoiceExportLink.presignedUrl = null;
        state.asfInvoiceExportLink.fileName = null;
        state.asfInvoiceDownloadStatus = "success";
        state.asfInvoiceExportStatus = "idle";
      })
      .addCase(asfInvoiceDownloadAsync.rejected, (state) => {
        state.asfInvoiceDownloadStatus = "failed";
      })
      .addCase(asfInvoiceExportAsync.pending, (state) => {
        state.asfInvoiceExportStatus = "processing";
      })
      .addCase(asfInvoiceExportAsync.fulfilled, (state, action) => {
        state.asfInvoiceExportLink = action.payload;
        state.asfInvoiceExportStatus = "success";
      })
      .addCase(asfInvoiceExportAsync.rejected, (state) => {
        state.asfInvoiceExportStatus = "failed";
      });
  },
});

export const selectCreateAsfInvoiceStatus = (state: RootState) =>
  state.asfInvoice.createAsfInvoiceStatus;
export const selectAsfInvoicesList = (state: RootState) =>
  state.asfInvoice.asfInvoicesList;
export const selectAsfInvoicesListStatus = (state: RootState) =>
  state.asfInvoice.asfInvoicesListStatus;
export const selectAsfInvoicetExportStatus = (state: RootState) =>
  state.asfInvoice.asfInvoiceExportStatus;
export const selectAsfInvoiceExportLink = (state: RootState) =>
  state.asfInvoice.asfInvoiceExportLink;

export default asfInvoiceSlice.reducer;
