import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import { TransactionsList, TransactionsListApi } from "./transactionsApi";

export interface TransactionState {
  transactionsListStatus: QueryStatus;
  transactionsList: TransactionsList[];
}

const initialState: TransactionState = {
  transactionsListStatus: "idle",
  transactionsList: [],
};

export const transactionsListAsync = createAsyncThunk(
  "transactionsList/call",
  async (payload: { companyUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<TransactionsListApi[]>(
      `/transactions/company/${payload.companyUuid}`,
    );
    return response.data
      .map((t) => {
        return {
          ...t,
          amount: t.amount.toString(),
          driverName: [t.driverFirstName, t.driverLastName]
            .filter((d) => d)
            .join(" "),
        };
      })
      .sort(
        (a, b) =>
          new Date(b.executionDate).getTime() -
          new Date(a.executionDate).getTime(),
      );
  },
);

export const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(transactionsListAsync.pending, (state) => {
        state.transactionsListStatus = "processing";
      })
      .addCase(transactionsListAsync.fulfilled, (state, action) => {
        state.transactionsList = action.payload;
        state.transactionsListStatus = "success";
      })
      .addCase(transactionsListAsync.rejected, (state) => {
        state.transactionsListStatus = "failed";
      });
  },
});

export const selectTransactionsList = (state: RootState) =>
  state.transactions.transactionsList;
export const selectTransactionsListStatus = (state: RootState) =>
  state.transactions.transactionsListStatus;

export default transactionSlice.reducer;
