import { CategoryType } from "../qg_front/supportingDocumentsApi";
import { t } from "i18next";

export const transactionsCategoryInfoList: CategoryType[] = [
  "FUEL_AUTOMATON",
  "SERVICE_STATION",
  "PARKING",
  "TOLL",
  "WASH",
  "ELECTRIC",
  "OTHER",
];

export const getCategoryName = (category: string | null): string => {
  if (!category) return "UNAUTHORIZED";
  const categoryTranslated = transactionsCategoryInfoList.find(
    (s) => t(`category.${s}`) === category,
  );
  return categoryTranslated ? category : "UNAUTHORIZED";
};
