import {
  ButtonDS,
  CheckBox,
  DialogModalDS,
  SelectDS,
  Spacer,
  TextCapitalized,
} from "@qivia/ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MerchantGroups } from "../supportingDocumentsApi";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { createMerchantNameAsync } from "../authorizationSlice";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";

const merchantGroups: MerchantGroups[] = [
  "SHELL",
  "BP",
  "TOTAL_ENERGIES",
  "ENI",
  "AVIA",
  "ESSO",
  "CASINO",
  "CARREFOUR",
  "INTERMARCHE",
  "AUCHAN",
  "SYSTEME_U",
  "LECLERC",
];

export const AuthorizationModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
  merchantName: string;
  authorizationId: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { visible, onCloseModal, merchantName, authorizationId } = props;
  const [merchantGroup, setMerchantGroup] = useState<MerchantGroups | null>(
    null,
  );
  const [isCreatedByMerchantName, setIsCreatedByMerchantName] =
    useState<boolean>(true);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const optionsMerchantGroup = useMemo(
    () =>
      merchantGroups.map((mg) => {
        return {
          value: mg,
          label: t(`merchantGroups.${mg}`),
        };
      }),
    [t],
  );

  const valueMerchantGroup = useMemo(
    () => optionsMerchantGroup.find((option) => option.value === merchantGroup),
    [merchantGroup, optionsMerchantGroup],
  );

  const resetModal = useCallback(() => {
    setDisplayError(false);
    setMerchantGroup(null);
    setIsCreatedByMerchantName(true);
    onCloseModal();
  }, [onCloseModal]);

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!merchantGroup) return;
    void dispatch(
      createMerchantNameAsync({
        merchantName: isCreatedByMerchantName ? merchantName : null,
        merchantGroup,
        authorizationId,
        isCreatedByMerchantName,
      }),
    );
    resetModal();
  }, [
    authorizationId,
    dispatch,
    isCreatedByMerchantName,
    merchantGroup,
    merchantName,
    resetModal,
  ]);

  return (
    <DialogModalDS
      visible={visible}
      onClose={resetModal}
      title={t("authorization.dialog.title")}
      width={40}
      bottomButton={
        <ButtonDS
          text={t(`authorization.button.title.confirm`)}
          format={"fill"}
          buttonType={"primary"}
          disabled={!merchantGroup}
          onClick={clickToSubmit}
        />
      }
    >
      <StyledColumn>
        <StyledRow>
          <TextCapitalized>
            {t("authorization.modal.merchantName")}
            {merchantName ?? ""}
          </TextCapitalized>
          <Spacer y={3} />
        </StyledRow>
        <SelectDS
          label={t("authorization.modal.merchantGroups") || ""}
          options={optionsMerchantGroup}
          placeholder={""}
          onChange={(e) => setMerchantGroup(e ? e.value : null)}
          value={valueMerchantGroup}
          isMandatory
          allWidth
          error={
            displayError && !merchantGroup
              ? t("authorization.modal.select.error")
              : undefined
          }
        />
        <StyledDanger>
          <TextCapitalized>
            {t(`authorization.dialog.description`)}
          </TextCapitalized>
        </StyledDanger>
        <Spacer y={0.5} />
        <StyledExemple>
          <TextCapitalized>{t(`authorization.dialog.exemple`)}</TextCapitalized>
        </StyledExemple>
        <Spacer y={1.5} />
        <CheckBox
          text={t("authorization.modal.checkbox")}
          state={isCreatedByMerchantName ? "UNCHECKED" : "CHECKED"}
          onClick={() => setIsCreatedByMerchantName(!isCreatedByMerchantName)}
        />
      </StyledColumn>
    </DialogModalDS>
  );
};

const StyledRow = styled.div`
  display: flex;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDanger = styled.div`
  ${typographies["Body/XXS"]};
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledExemple = styled.div`
  ${typographies["Body/XXS"]};
  color: ${colors["colors/text/black"]};
`;
