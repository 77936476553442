import "./App.css";
import { useRoutes } from "react-router-dom";
import { routes } from "./routes/router";
import "./i18n/i18n";
import { LicenseInfo } from "@mui/x-license";

const licenceKey = import.meta.env["VITE_MUI_LICENCE_KEY"] as string;

LicenseInfo.setLicenseKey(licenceKey);

const App = () => useRoutes(routes);
export default App;
