import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import {
  RejectedAuthorizationsApi,
  RejectedAuthorizationsList,
} from "./rejectedAuthorizationsApi";

export interface AuthorizationsState {
  rejectedAuthorizationsListStatus: QueryStatus;
  rejectedAuthorizationList: RejectedAuthorizationsList[];
}

const initialState: AuthorizationsState = {
  rejectedAuthorizationsListStatus: "idle",
  rejectedAuthorizationList: [],
};

export const rejectedAuthorizationsAsync = createAsyncThunk(
  "companyRejectedAuthorizations/call",
  async (payload: { companyUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<RejectedAuthorizationsApi[]>(
      `/authorizations_rejected/company/${payload.companyUuid}`,
    );
    return response.data
      .map((t) => {
        return {
          ...t,
          merchantGroupCreation: "",
          amount: t.amount / 100,
          reasonsRefused: t.reasonsRefused.join(","),
          driverName:
            t.driverLastName && t.driverFirstName
              ? t.driverLastName + " " + t.driverFirstName
              : null,
        };
      })
      .sort(
        (a, b) =>
          new Date(b.executionDate).getTime() -
          new Date(a.executionDate).getTime(),
      );
  },
);

export const rejectedAuthorizationsSlice = createSlice({
  name: "rejectedAuthorizations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetAuthorizationsListStatus(state) {
      state.rejectedAuthorizationsListStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(rejectedAuthorizationsAsync.pending, (state) => {
        state.rejectedAuthorizationsListStatus = "processing";
      })
      .addCase(rejectedAuthorizationsAsync.fulfilled, (state, action) => {
        state.rejectedAuthorizationsListStatus = "success";
        state.rejectedAuthorizationList = action.payload;
      })
      .addCase(rejectedAuthorizationsAsync.rejected, (state) => {
        state.rejectedAuthorizationsListStatus = "failed";
      });
  },
});

export const selectRejectedAuthorizationsList = (state: RootState) =>
  state.authorizations.rejectedAuthorizationList;
export const selectRejectedAuthorizationsListStatus = (state: RootState) =>
  state.authorizations.rejectedAuthorizationsListStatus;

export default rejectedAuthorizationsSlice.reducer;
